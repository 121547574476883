var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item-group',{staticStyle:{"min-height":"85vh","width":"100%"}},[_c('v-container',{staticStyle:{"background":"rgba(255,255,255,0.9)","height":"100%"}},[_c('v-row',{staticStyle:{"font-size":"18px","padding":"0","margin":"0"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative","padding":"0 20px","text-align":"center","margin-top":"20px"},attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"font-weight":"bold","margin":"auto"}},[_vm._v(" ATTENDANCE REPORT SYSTEM ")])]),_c('v-col',{staticStyle:{"margin-top":"30px"},attrs:{"cols":"12"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"color":!hover ? '#385F73' : '#1F7087',"dark":"","elevation":!hover ? 6 : 10},on:{"click":toggle}},[_c('p',{staticClass:"text-uppercase text-right",staticStyle:{"font-size":"32px","padding":"5px 10px 5px","margin":"0"}},[_vm._v(" total karyawan suhu abnormal ")]),_c('p',{staticClass:"text-right",staticStyle:{"font-size":"20px","padding-right":"10px","margin":"5px"}},[_vm._v(" 0 ")]),_c('p',{staticClass:"d-flex",staticStyle:{"border-top":"1px solid hsla(70, 100%, 50%,9)","padding":"10px 0 10px 10px","font-size":"14px","margin":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(new Date()))])],1)])]}}],null,true)})]}}])})],1),_c('v-col',{staticStyle:{"margin-top":"100px","padding":"0 20px"},attrs:{"cols":"6"}},[_c('v-card',{attrs:{"color":'#1F7087',"dark":"","elevation":"6"}},[_c('p',{staticClass:"text-uppercase text-right",staticStyle:{"font-size":"14px","padding":"5px 10px 5px","margin":"0"}},[_vm._v(" total kehadiran ")]),_c('p',{staticClass:"text-right",staticStyle:{"font-size":"20px","padding-right":"10px","margin":"5px"}},[_vm._v(" 0 ")]),_c('p',{staticClass:"d-flex",staticStyle:{"border-top":"1px solid hsla(70, 100%, 50%,9)","padding":"10px","font-size":"14px","margin":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm.dateToday))])],1)])],1),_c('v-col',{staticStyle:{"margin-top":"100px","padding":"0 20px"},attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"color":!hover ? '#1F7087' : '#1F8082',"dark":"","elevation":!hover ? 6 : 10},on:{"click":toggle}},[_c('p',{staticClass:"text-uppercase text-right",staticStyle:{"font-size":"14px","padding":"5px 10px 5px","margin":"0"}},[_vm._v(" total karyawan terlambat ")]),_c('p',{staticClass:"text-right",staticStyle:{"font-size":"20px","padding-right":"10px","margin":"5px"}},[_vm._v(" 0 ")]),_c('p',{staticClass:"d-flex",staticStyle:{"border-top":"1px solid hsla(70, 100%, 50%,9)","padding":"10px","font-size":"14px","margin":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm.dateToday))])],1)])]}}],null,true)})]}}])})],1),_c('v-col',{staticStyle:{"margin-top":"100px","padding":"0 20px"},attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"color":!hover ? '#1F7087' : '#1F8082',"dark":"","elevation":!hover ? 6 : 10},on:{"click":toggle}},[_c('p',{staticClass:"text-uppercase text-right",staticStyle:{"font-size":"14px","padding":"5px 10px 5px","margin":"0"}},[_vm._v(" total karyawan pulang awal ")]),_c('p',{staticClass:"text-right",staticStyle:{"font-size":"20px","padding-right":"10px","margin":"5px"}},[_vm._v(" 0 ")]),_c('p',{staticClass:"d-flex",staticStyle:{"border-top":"1px solid hsla(70, 100%, 50%,9)","padding":"10px","font-size":"14px","margin":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm.dateToday))])],1)])]}}],null,true)})]}}])})],1),_c('v-col',{staticStyle:{"margin-top":"100px","padding":"0 20px"},attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"cursor":"pointer"},attrs:{"color":!hover ? '#1F7087' : '#1F8082',"dark":"","elevation":!hover ? 6 : 10},on:{"click":toggle}},[_c('p',{staticClass:"text-uppercase text-right",staticStyle:{"font-size":"14px","padding":"5px 10px 5px","margin":"0"}},[_vm._v(" total karyawan lembur ")]),_c('p',{staticClass:"text-right",staticStyle:{"font-size":"20px","padding-right":"10px","margin":"5px"}},[_vm._v(" 0 ")]),_c('p',{staticClass:"d-flex",staticStyle:{"border-top":"1px solid hsla(70, 100%, 50%,9)","padding":"10px","font-size":"14px","margin":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm.dateToday))])],1)])]}}],null,true)})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }