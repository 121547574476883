<template>
  <v-item-group style="min-height:85vh; width:100%;">
    <v-container style="background:rgba(255,255,255,0.9);height:100%;">
      <v-row
        no-gutters
        align="center"
        style="font-size:18px;padding:0;margin:0;"
      >
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h3 style="font-weight:bold;margin:auto;">
            ATTENDANCE REPORT SYSTEM
          </h3>
        </v-col>
        <v-col cols="12" style="margin-top:30px;">
          <v-item v-slot="{ toggle }">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="toggle"
                :color="!hover ? '#385F73' : '#1F7087'"
                dark
                :elevation="!hover ? 6 : 10"
                style="cursor:pointer;"
              >
                <p
                  class="text-uppercase text-right"
                  style="font-size:32px;padding:5px 10px 5px;margin:0;"
                >
                  total karyawan suhu abnormal
                </p>
                <p
                  style="font-size:20px;padding-right:10px;margin:5px;"
                  class="text-right"
                >
                  0
                </p>

                <p
                  class="d-flex"
                  style="border-top:1px solid hsla(70, 100%, 50%,9);padding:10px 0 10px 10px;font-size:14px;margin:0;"
                >
                  <v-icon small style="margin-right:5px;">mdi-calendar</v-icon>
                  <span>{{ new Date() }}</span>
                </p>
              </v-card>
            </v-hover>
          </v-item>
        </v-col>
        <v-col cols="6" style="margin-top:100px;padding: 0 20px;">
          <v-card :color="'#1F7087'" dark elevation="6">
            <p
              class="text-uppercase text-right"
              style="font-size:14px;padding:5px 10px 5px;margin:0;"
            >
              total kehadiran
            </p>
            <p
              style="font-size:20px;padding-right:10px;margin:5px;"
              class="text-right"
            >
              0
            </p>

            <p
              class="d-flex"
              style="border-top:1px solid hsla(70, 100%, 50%,9);padding:10px;font-size:14px;margin:0;"
            >
              <v-icon small style="margin-right:5px;">mdi-calendar</v-icon>
              <span>{{ dateToday }}</span>
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" style="margin-top:100px;padding: 0 20px;">
          <v-item v-slot="{ toggle }">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="toggle"
                :color="!hover ? '#1F7087' : '#1F8082'"
                dark
                :elevation="!hover ? 6 : 10"
                style="cursor:pointer;"
              >
                <p
                  class="text-uppercase text-right"
                  style="font-size:14px;padding:5px 10px 5px;margin:0;"
                >
                  total karyawan terlambat
                </p>
                <p
                  style="font-size:20px;padding-right:10px;margin:5px;"
                  class="text-right"
                >
                  0
                </p>

                <p
                  class="d-flex"
                  style="border-top:1px solid hsla(70, 100%, 50%,9);padding:10px;font-size:14px;margin:0;"
                >
                  <v-icon small style="margin-right:5px;">mdi-calendar</v-icon>
                  <span>{{ dateToday }}</span>
                </p>
              </v-card>
            </v-hover>
          </v-item>
        </v-col>
        <v-col cols="6" style="margin-top:100px;padding: 0 20px;">
          <v-item v-slot="{ toggle }">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="toggle"
                :color="!hover ? '#1F7087' : '#1F8082'"
                dark
                :elevation="!hover ? 6 : 10"
                style="cursor:pointer;"
              >
                <p
                  class="text-uppercase text-right"
                  style="font-size:14px;padding:5px 10px 5px;margin:0;"
                >
                  total karyawan pulang awal
                </p>
                <p
                  style="font-size:20px;padding-right:10px;margin:5px;"
                  class="text-right"
                >
                  0
                </p>

                <p
                  class="d-flex"
                  style="border-top:1px solid hsla(70, 100%, 50%,9);padding:10px;font-size:14px;margin:0;"
                >
                  <v-icon small style="margin-right:5px;">mdi-calendar</v-icon>
                  <span>{{ dateToday }}</span>
                </p>
              </v-card>
            </v-hover>
          </v-item>
        </v-col>
        <v-col cols="6" style="margin-top:100px;padding: 0 20px;">
          <v-item v-slot="{ toggle }">
            <v-hover v-slot="{ hover }">
              <v-card
                @click="toggle"
                :color="!hover ? '#1F7087' : '#1F8082'"
                dark
                :elevation="!hover ? 6 : 10"
                style="cursor:pointer;"
              >
                <p
                  class="text-uppercase text-right"
                  style="font-size:14px;padding:5px 10px 5px;margin:0;"
                >
                  total karyawan lembur
                </p>
                <p
                  style="font-size:20px;padding-right:10px;margin:5px;"
                  class="text-right"
                >
                  0
                </p>

                <p
                  class="d-flex"
                  style="border-top:1px solid hsla(70, 100%, 50%,9);padding:10px;font-size:14px;margin:0;"
                >
                  <v-icon small style="margin-right:5px;">mdi-calendar</v-icon>
                  <span>{{ dateToday }}</span>
                </p>
              </v-card>
            </v-hover>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
// import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    attendance: buildType.apiURL('attendance'),
    toggle: false,
    dateToday: '',
    tabs: [
      { tab_name: 'Dashboard', path: '/atd' },
      { tab_name: 'Laporan Presensi', path: '/atd/attendance' },
      { tab_name: 'Laporan Mesin Presensi', path: '/atd/attendance' }
      // { tab_name: 'Laporan Mesin Presensi', path: '/atd/machine' },
      // { tab_name: 'Laporan Akses Pintu', path: '/atd/door_access' }
    ],
    abnormal: '',
    presence: '',
    lateness: '',
    early_out: '',
    over_time: ''
  }),
  mounted() {
    const d = new Date()
    d.setDate(d.getDate() - 1)
    const n = d
      .toLocaleDateString()
      .split('/')
      // .reverse()
      .join('-')
    this.dateToday = n
    this.setTabs(this.tabs)
  },
  methods: {
    ...mapMutations(['setTabs'])
  }
}
</script>
